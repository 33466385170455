@import "./authorization.scss";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: "Verdana";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-width: 1000px;

  .ms-ComboBox-callout {
    min-width: 85px !important;
  }

  .ms-Callout {
    min-width: 85px !important;
  }

  .react-pdf__Page__canvas,
  .react-pdf__Page__svg {
    width: 100% !important;
    height: 100% !important;
  }

  .react-pdf__Document {
    width: 100%;
  }

  svg {
    width: 100%!important;
    height: 100%!important;
  }

  .scrollableContent-115 {
    width: 70vw;
  }
}
