#authorization {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .login-panel {
        width: 500px;
        background-color: #fff;
        box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;

        .title {
            font-size: 24px;
            font-weight: 600;
            flex: 1 1 auto;
            border-top: 4px solid rgb(0, 120, 212);
            color: rgb(50, 49, 48);
            display: flex;
            align-items: center;
            padding: 12px 12px 14px 24px;
        }

        .inputs {
            padding: 0px 12px 14px 24px;
        }

        .btns {
            display: flex;
            justify-content: space-between;
            padding: 0px 12px 14px 24px;
        }
    }
}
